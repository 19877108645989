<template>
  <div id="app"> 
      <aside>
        <SideBarMenu v-if="isPropertySideBarVisible"/>
      </aside>
      <router-view />
  </div>
</template>


<script>
import SideBarMenu from './components/SideBarMenu.vue';


export default {
  name: 'App',
  components: {
    SideBarMenu
  },
  computed: {
    isPropertySideBarVisible() {
      return this.$store.getters.isPropertySideBarVisible;
    },
  },
};
</script>

<style>
#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
  color: #222222;
}

aside {
  position: fixed;
}


</style>
