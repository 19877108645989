<template>
  <h1>
    This is a demo
  </h1>
</template>

<script>
import ViewMixin from '@/mixins/viewMixin';


export default {
    name: 'DashboardView',
    mixins: [ViewMixin]
}
</script>

<style>

</style>