import './assets/styles/tailwind.css';
import './assets/styles/global.css';
import router from './router/index.js'; 
import store from './store/index.js'; 

import { createApp } from 'vue';
import App from './App.vue';

const app = createApp(App);

app.use(router);
app.use(store);  // Register the store with Vue
app.mount('#app');
