// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { auth } from "../firebase";

import LoginView from '../views/LoginView.vue'; 
import DashboardView from '../views/property-management/DashboardView.vue'; 
import MessagesView from '@/views/property-management/MessagesView.vue';
import ViewingsView from '@/views/property-management/ViewingsView.vue';
import PaymentsView from '@/views/property-management/PaymentsView.vue';
import AccountView from '@/views/property-management/AccountView.vue';
import SupportView from '@/views/property-management/SupportView.vue';

const routes = [
  { path: '/', component: LoginView},
  { path: '/home', component: DashboardView, meta: { requiresAuth: true }, },
  { path: '/messages', component: MessagesView , meta: { requiresAuth: true },},
  { path: '/calendar', component: ViewingsView, meta: { requiresAuth: true }, },
  { path: '/payments', component: PaymentsView, meta: { requiresAuth: true }, },
  { path: '/account', component: AccountView, meta: { requiresAuth: true }, },
  { path: '/support', component: SupportView, meta: { requiresAuth: true }, },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    auth.onAuthStateChanged((user) => {
      if (user) {
        next();
      } else {
        next("/");
      }
    });
  } else {
    next();
  }
});

export default router;
