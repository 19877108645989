// store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      currentComponent: '', // The name of the current component
      visibleComponents: ['DashboardView', 'PropertyDetailView', 'MessagesView','ViewingsView', 'PaymentsView', 'SupportView', 'AccountView', ], // List of components where sidebar should be visible
    };
  },
  mutations: {
    // Private mutation (internally used)
    SET_CURRENT_COMPONENT(state, componentName) {
      state.currentComponent = componentName;
    },
  },
  actions: {
    // Action decides whether to show or hide the sidebar, and only exposes this functionality externally
    showSideBarDependingOnComponent({ commit }, componentName) {
      commit('SET_CURRENT_COMPONENT', componentName); // Store the current component name
    },
  },
  getters: {
    isPropertySideBarVisible(state) {
      // Show the sidebar if the current component is in the list of visible components
      return state.visibleComponents.includes(state.currentComponent);
    },
  },
});

export default store;
