<template>
    <div class="login">
        <div class="image-container">
            <img alt="Casium logo" src="@/assets/logo.png">
        </div>
        <form @submit.prevent="handleSubmit" novalidate>
      <div class="pb-default">
        <input
          type="email"
          id="email"
          v-model="email"
          placeholder="Enter your email"
          class="hover-no-outline  bg-secondary"
        />
      </div>
        <div class="pb-default">
                <input
                    type="password"
                    id="password"
                    v-model="password"
                    placeholder="Enter your password"
                    class="hover-no-outline  bg-secondary"
            />
      </div>
      <button type="submit" class="bg-primary transition-all transform hover:scale-105 hover:shadow-lg mb-default">Login</button>
      <p v-if="errorMessage" class="login-error pt-default">{{ errorMessage }}</p>
    </form>
    </div>
</template>
  
<script>
import ViewMixin from '@/mixins/viewMixin';
import { signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "@/firebase.js"

const authErrorMessages = {
  "auth/invalid-credential": "Invalid login credentials. Please try again.",
  "auth/user-not-found": "No user found with this email",
  "auth/wrong-password": "The password is incorrect. Please try again.",
  "auth/invalid-email": "Invalid email format",
};

    export default {
        name: 'LoginView',
        mixins: [ViewMixin], 
        data(){
            return {
                email: "",
                password: "", 
                errorMessage: "", 
            };
        },
        methods: {
            handleSubmit(){
              if(!this.email){
                this.errorMessage = "Please enter your email"
                return; 
              }

              if(!this.validateEmail(this.email)){
                this.errorMessage = "Invalid email format"
                return; 
              }

              if(!this.password){
                this.errorMessage = "Please enter your password"
                return; 
              }

              this.login()
            },
            async login() {
              try {
                await signInWithEmailAndPassword(auth, this.email, this.password);
                this.$router.push("/home");
              } catch (error) {
                console.log(error.message); 
                this.errorMessage = this.getCustomErrorMessage(error.code);
              }
            },
            validateEmail(email){
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              return emailRegex.test(email);
            },
            getCustomErrorMessage(errorCode) {
              // Fallback to a default error message if the error code isn't mapped
              return authErrorMessages[errorCode] || "An unexpected error occurred. Please try again.";
            },
        }
    } 
</script>
  
<style scoped>
.login {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.login-error{
  color: red;
}

h2 {
  text-align: center;
}

input::placeholder{
    color: black !important;
}

label {
  display: block;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  margin-bottom: 10;
}

button {
  width: 100%;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 5%;
  cursor: pointer;
}
</style>
