<template>
    <button :class="{'!bg-quarternary': selected, 'sidebar-menu-button': true}"
    class="flex items-center"
    @click="handleClick">
      <div class="ml-default mr-default w-[20px] h-[20px] flex items-center justify-center">
        <slot></slot>
      </div>
      <span class="w-default"></span>
        {{ text }}
  </button>
</template>

<script>

export default {
  name: "SideMenuButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      default: null,
    },
    selected: {
        type: Boolean, 
        default: true
    }
  },
  methods: {
    handleClick() {
      if (this.onClick) {
        this.onClick();
      }
    },
  },
  mounted(){
    console.log(this.selected); 
  }
};
</script>

<style>

</style>