/* eslint-disable */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXnR_2cOSNzsVkF09O4Oj_DpuTwSmTet4",
  authDomain: "casium-526fd.firebaseapp.com",
  projectId: "casium-526fd",
  storageBucket: "casium-526fd.firebasestorage.app",
  messagingSenderId: "643784524176",
  appId: "1:643784524176:web:f3e16133e06aa498733315",
  measurementId: "G-JRHQER2KVB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
