<template>
    <div>
        
    </div>
</template>
<script>
import ViewMixin from '@/mixins/viewMixin';


export default {
    name: "MessagesView",
    mixins: [ViewMixin]
}
</script>
<style >
    
</style>