<template>
    <div class="flex justify-center">
        <div class="w-80">
            <button 
            class="sidebar-menu-button"
            
            @click="logOut"
            >
                Demo Log Out
            </button>
        </div>
    </div>
</template>
<script>
import ViewMixin from '@/mixins/viewMixin';
import { signOut } from "firebase/auth";
import {auth} from '@/firebase'; 


export default {
    name: "AccountView",
    mixins: [ViewMixin],
    methods: {
        logOut(){
            signOut(auth).then(() => {
                this.$router.push('/')
            })
        }
    }
}
</script>
<style >
    
</style>