export default {
    name: 'ViewMixin',
    mounted() {
      // Safely access component name or pass the name explicitly
      const componentName = this.$options.name;
  
      if (componentName) {
        // Dispatch the action to the store with the component name
        this.$store.dispatch('showSideBarDependingOnComponent', componentName);
      } else {
        console.warn('Component name not found. Ensure the component has a "name" property set.');
      }
    },
  };
  