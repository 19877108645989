<template>
  <div class = "bg-secondary sidebar-menu flex flex-col justify-between h-full">
    <div class="flex flex-col">
        <div class = "logo-container">
            <img alt="Casium logo" src="@/assets/logo.png" class="logo-image">
        </div>
        <div class="mb-default">
            <SideMenuButton text="My Properties" :onClick="()=>{navigate('home')}" :selected="selected==='DashboardView'">
                <img src="@/assets/icons/room.svg" alt = "room icon">
            </SideMenuButton>
        </div>
        <div class="mb-default">
        <SideMenuButton text="Messages" :onClick="()=>{navigate('messages')}" :selected="selected==='MessagesView'" >
            <img src="@/assets/icons/message.svg" alt = "message icon">
        </SideMenuButton>
        </div>
        <div class="mb-default">
        <SideMenuButton text="Viewings" :onClick="()=>{navigate('calendar')}" :selected="selected==='ViewingsView'" >
            <i class="material-icons">calendar_month</i>
        </SideMenuButton>
        </div>
        <div class="mb-default">
        <SideMenuButton text="Payments" :onClick="()=>{navigate('payments')}" :selected="selected==='PaymentsView'" >
            <i class="material-icons">euro_symbol</i>
        </SideMenuButton>
        </div>
    </div>
    <div class="flex flex-col">
        <div class="mb-default">
            <SideMenuButton text="My Account" :onClick="()=>{navigate('account')}" :selected="selected==='AccountView'">
                <i class="material-icons">person</i>
            </SideMenuButton>
        </div>
        <SideMenuButton text="Talk to Support" :onClick="()=>{navigate('support')}" :selected="selected==='SupportView'">
            <i class="material-icons">support_agent</i>
        </SideMenuButton>
    </div>
  </div>
</template>

<script>
import SideMenuButton from '@/components/SideMenuButton.vue';

export default {
    name: "SideBarMenu", 
    components: {
        SideMenuButton, 
    },
    methods: {
        navigate(route){
            this.$router.push(`/${route}`);
        }
    },
    computed: {
        selected() {
            return this.$store.state.currentComponent;
        },
    },
}
</script>

<style>
    .sidebar-menu{
        position: fixed;
        top: 0;          
        left: 0;         
        height: 100vh;  
        width: 300px;    
        padding: 20px;
        padding-bottom: 3%;
    }
    .logo-container {
        padding-top: 20px;
        width: 100%; 
        height: 150px; 
        overflow: hidden; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
    }
    .logo-image{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
</style>
